.menu {
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  position: fixed;
  z-index: 12;
  .menu-mask {
      height: calc(100vh - 44px);
      width: 100%;
      background: rgba(0,0,0,0.4);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 44px;
      z-index: 10;
  }

  .menu-content {
    z-index: 12;
    height: 44px;
    background: #b31f27;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: absolute;
    width: 100%;
    img {
      height: 36px;
      &:nth-of-type(2) {
        height: 18px;
        width: 20px;
      }
    }
  }

  .menu-list {
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    animation: navTranstion 0.8s ease;
    z-index: 11;
    li {
      list-style: none;
      border-bottom: 1px solid #eee;
      background-color: #fff;
    }
    a {
      display: block;
      text-decoration: none;
      height: 54px;
      line-height: 54px;
      font-size: 15px;
      text-align: center;
      color: #333;
      font-weight: 700;
    }
  }

  .menu-active{
    color: #B31F27 !important;
  }

  @keyframes navTranstion {
    0% {
      transform: translateY(-275px);
    }
    100% {
      transform: translateY(0);
    }
    
  }
}
