.map-footer {
  background: #333333;
  padding: 18px 0;
  .top {
    display: flex;
    flex-direction: row;
    padding: 0 18px;
    img {
      width: 88px;
      height: 88px;
      margin-right: 12px;
    }
    .contact {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 88px;
      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        color: #fff;
        font-size: 12px;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .center {
    padding: 10px 18px;
    border-bottom: 1px solid #fff;
    margin-top: 10px;
    .link-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      a {
        color: #fff;
      }
      li{
        padding: 0 10px;
        &:nth-of-type(2){
          border-left:1px dashed #fff ;
          border-right:1px dashed #fff ;
        }
      }
    }
  }
  .bottom {
    padding: 10px;
    p {
      line-height: 18px;
      text-align: center;
      color: #999999;
      &:nth-of-type(1) {
        margin-bottom: 10px;
      }
    }
  }
}
